<template>
  <div class="card-item" :style="{ backgroundImage: `url(${bgUrl})` }" @click="handleClickCard">
    <p class="title">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'ImgCard',
  props: {
    // 文字内容
    title: {
      type: String,
      required: true
    },
    // 放在 assets/images 目录下的所需图片 【文件名】
    bgName: {
      type: String,
      default: 'img-card-introduction.png'
    }
  },
  computed: {
    bgUrl() {
      const url = require(`../../../../assets/images/${this.bgName}`);
      return url;
    }
  },
  methods: {
    handleClickCard() {
      switch (this.title) {
        case '公司简介':
          this.$router.push({
            path: '/introduction',
            query: { params: 0 }
          });
          break;
        case '企业文化':
          this.$router.push({
            path: '/introduction',
            query: { params: 3 }
          });
          break;
        case '办公地点':
          this.$router.push({
            path: '/introduction',
            query: { params: 4 }
          });
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.card-item {
  position: relative;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  // background-image: url('~@/assets/images/img-card-introduction.png');
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease-out;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  &:hover {
    transform: scale(1.1);
  }

  &:hover::after {
    background: rgba(0, 0, 0, 0.7);
  }

  &:hover .title {
    font-weight: bold;
    color: #1d7df8;
  }

  &:hover .title::after {
    opacity: 1;
    width: 144px;
  }

  .title {
    position: relative;
    width: 144px;
    height: 50px;
    font-size: 36px;
    line-height: 50px;
    color: #ffffff;
    z-index: 10;
  }

  .title::after {
    content: '';
    opacity: 0;
    position: absolute;
    left: 0;
    bottom: -14px;
    width: 0;
    height: 0px;
    border: 2px solid #1d7df8;
    transition: all 0.5s;
  }
}
</style>
